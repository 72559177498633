@font-face {
  font-family: 'ValkyRegular';
  src: url('./fonts/ValkyRegular.ttf') format('truetype');
}

@font-face {
  font-family: 'AvenirNextCyr-Regular';
  src: url('./fonts/AvenirNextCyr-Regular.woff') format('truetype');
}

@font-face {
  font-family: 'AvenirNextCyr-Bold';
  src: url('./fonts/AvenirNextCyr-Bold.woff') format('truetype');
}

@font-face {
  font-family: 'HelveticaNeueRoman';
  src: url('./fonts/HelveticaNeueRoman.otf') format('truetype');
}

/* ==== Animations ==== */

.fade-in {
  opacity: 0;
  transition: opacity 0.8s ease-out;
}

.fade-in.show {
  opacity: 1;
}

/* ==== Contact Page ==== */

.contact-page-container {
  padding: 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 12em 0;
}

.contact-form {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 2em;
  padding: 20px;
  border: 1px solid #231F20;
  border-radius: 20px;
  text-align: center;
  box-sizing: border-box;
}

.contact-form h2 {
  font-size: 20px;
  color: #545454;
  margin-bottom: 0.5em;
}

.contact-form-rows {
  background-color: #F4F0EC;
  border-radius: 20px;
  font-size: 15px;
  padding: 1em;
  margin-bottom: 1em;
  text-align: left;
}

/* ==== FAQs ==== */

.faqs-page-container {
  padding: 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 12em 0;
}

.faqs-list {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 1em;
}

.faq-item {
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
}

.faq-question {
  display: flex;
  align-items: center;
  cursor: pointer;

}

.faq-question h3 {
  color: #545454;
  text-transform: uppercase;
  font-size: 16px;
  margin: 0;
  flex-grow: 1;
}

.faq-icon {
  color: #545454;
  font-size: 20px;
  transition: transform 0.3s ease;
}

.faq-answer {
  padding: 15px 0;
}

.faq-answer p{
  font-size: 18px;
  color: #545454;
}

/* ==== Terms ==== */

.terms-page {
  margin: 10em 0 2em 0;
  padding: 3em;
}

.terms-page h2 {
  font-size: 35px;
  margin-bottom: 1em;
}

.terms-page p  {
  font-size: 16px;
  margin-bottom: 1em;
}

.terms-page h3 {
  font-size: 18px;
  margin-bottom: 1em;
  text-transform: uppercase;
  color: #231f20;
}

/* === Forgot Password ===*/

.forgot-password-container {
  padding: 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 12em 0;
}

.forgot-password-link {
  font-family: 'AvenirNextCyr-Regular', sans-serif;
  font-size: 16px;
  color: #545454;
  text-transform: none;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  background-color: transparent;
}

.popup-footer button{
  width: auto;
}

.reset-password-page-container {
  padding: 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 12em 0;
}

.reset-password-page-container .popup-intro-description {
  flex-direction: column;
  gap: 2em;
}

.reset-password-page-container .popup-background {
  background-color: rgba(0, 0, 0, 0.9);
}


/* Preloader */
.preloader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(244, 240, 236, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.preloader-content {
  text-align: center;
}

.preloader-content img {
  max-width: 10rem;
  width: 100%;
  animation: fade 1.4s ease-in-out infinite;
}

@keyframes fade {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

/* LoadingSpinner */
.loading-spinner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  
}

.spinner-border {
  width: 1.8rem;
  height: 1.8rem;
  border: 0.2em solid rgba(0, 0, 0, 0.1);
  border-top: 0.2em solid #26A282;
  border-radius: 50%;
  animation: spin 0.75s linear infinite;
}

.loading-spinner-message {
  font-size: 0.8em;
  text-transform: uppercase;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* === Chat === */

.empty-page {
  min-height: 60vh;
}

.user-chat-page {
  padding: 8em 0 0 0;
  display: flex;
  flex-direction: column;
}

.chat-container {
  display: flex;
  flex: 1; 
  overflow: hidden;

}

.chat-container .loading-spinner {
  height: 60vh;
}

.no-conversations-message {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  text-align: center;
  padding: 0 1em;
}

.no-conversations-message h2 {
  font-size: 2.2em;
  margin-bottom: 0.5em;
}

.no-conversations-message p {
  font-size: 1.3em;
  max-width: 50%;
  line-height: 1.3em;
}

.no-conversations-message a {
  font-size: inherit;
  color: #26A282;
}

.chat-sidebar-container {
  width: 100%;
  background-color: #F4F0EC;
  position: relative;
  display: none; 
  transition: transform 0.3s ease;
  z-index: 20; 
  overflow-y: auto;
  box-sizing: border-box;
  border-right: 1px solid #ddd;
}

.chat-conversation-container {
  height: 82vh;
  display: flex;
  overflow: hidden;
  width: 100%;
  transition: margin-left 0.3s ease;
  box-sizing: border-box;
}

.choose-conversation {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
}

.choose-conversation p{
  font-size: 1rem;
}

.chat-conversation {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  height: 100%;
  box-sizing: border-box;
  /*overflow-y: auto;*/
}

.conversation-header {
  display: flex;
  align-items: center;
  padding: 0.5em;
  border-bottom: 1px solid #ddd;
  background-color: #F4F0EC;
  box-sizing: border-box;
  margin: 0;
}

.chat-advice-fixed-message {
  text-align: center;
  color:#26A282;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-advice-fixed-message p{
  max-width: 75%;
  font-size: 14px;
}

.conversation-header img.profile-pic {
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.conversation-header h3 {
  text-transform: uppercase;
  margin: 0;
  font-size: 0.9rem;
}

.conversation-footer {
  box-sizing: border-box;
  background-color: #F4F0EC;
}

.conversation-messages {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  padding: 0.5em;
}

/* Chat: Sidebar Conversations */

.chat-sidebar {
  padding: 1em 0em 1em 1em;
}

.chat-sidebar-container.closed {
  width: 50px;
  overflow: hidden;
}

.chat-sidebar-container.closed .conversation-items{
  display: none;
}

.chat-sidebar-container.closed .chat-sidebar-header h3{
  display: none;
}

.chat-sidebar.closed{
  overflow: hidden;
  height: 100%;
  box-sizing: border-box;
}

.chat-sidebar-container.closed .chat-sidebar-header{
  height: 100%;
}

.chat-sidebar-container.open {
  height: 82vh;
}

.chat-sidebar-header h3{
  font-size: 1rem;
  font-weight: 100;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}

.toggle-sidebar-button {
  color: #231F20;
  padding: 0;
  margin-bottom: 1em;
  background-color: transparent;
  font-weight: 100;
}

.conversation-items {
  gap: 0.2em;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.conversation-item {
  display: flex;
  align-items: center;
  padding: 0.5em 0em 0.5em 0.5em;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
  transition: background-color 0.3s;
}

.conversation-item.active {
  border: 1px solid #ddd;
  background-color: #fff;
  border-right: 0;
  border-bottom-left-radius:40px;
  border-top-left-radius:40px;

}

.conversation-item:hover {
  opacity: 0.5;
}

.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.conversation-info {
  display: flex;
  flex-direction: column;
}

.conversation-partner p{
  text-transform: uppercase;
  font-size: 0.9rem;
}

.last-message {
  font-size: 0.8rem;
}


/* Chat: Messages & Input */

.date-separator {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0;
}

.date-separator span{
  border-radius: 8px;
  padding: 0.5em;
  color: #545454;
  font-size: 0.8rem;
  background-color: #F4F0EC;
  border: none;
}

.message {
  display: flex;
  justify-content: flex-start;
  margin: 5px 0;
  padding: 0.5em;
  border-radius: 10px;
  max-width: 35%;
  word-wrap: break-word;
}

.message-content {
  display: flex;
  flex-direction: column;
  position: relative;
}

.message p {
  font-size: 0.875rem; 
  margin: 0;
}

.message-sender {
  font-size: 0.75rem; 
  font-weight: bold;
  margin-bottom: 5px;
}

.message-timestamp {
  margin-top: 0.3em;
  font-size: 0.75rem; 
  color: #666;
  bottom: 5px;
}

.own-message {
  background-color: #dcf8c6;
  align-self: flex-end;
  margin-left: auto;
}

.other-message {
  background-color: aliceblue;
  align-self: flex-start;
  margin-right: auto;
}

.message-input-form {
  display: flex;
  gap: 0.5em;
  padding: 0.5em;
  border-top: 1px solid #ddd;
}

.message-input-form input {
  flex: 1;
  padding: 0.5em;
  border: 1px solid #ddd;
  border-radius: 20px;
}

.send-btn {
  background-color:#26A282
}






/* === General ===*/

body {
  font-family: 'AvenirNextCyr-Regular', sans-serif;
  color: #545454;
  letter-spacing: 1px;
  line-height: 1.2;
}

h1 {
  font-size: 24px;
}

h2 {
  font-family: 'AvenirNextCyr-Bold', sans-serif;
  color: #231f20;
  font-size: 40px;
  font-weight: 800;
  text-transform: uppercase;
  margin: 0;
}

h3 {
  font-family: 'AvenirNextCyr-Bold', sans-serif;
  margin:0;
}

h4 {
  font-family: 'AvenirNextCyr-Bold', sans-serif;
}

h5 {
  font-family: 'AvenirNextCyr-Bold', sans-serif;
}

p {
  font-family: 'AvenirNextCyr-Regular', sans-serif;
  font-size: 22px;
  margin: 0;
}

a {
  font-family: 'AvenirNextCyr-Regular', sans-serif;
  font-size: 15px;
  color: #545454; 
  text-decoration: none;
  cursor: pointer;
}

/* === Buttons === */

button {
  font-family: 'AvenirNextCyr-Bold', sans-serif;
  font-size: 14px;
  letter-spacing: 0.6px;
  padding: 12px 24px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  text-transform: uppercase;
}

.primary-btn {
  background-color: #26A282;
  color: #231F20;
}

.secondary-btn {
  background-color: #E8AAC9;
  color: #fff;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button:hover{
  opacity: 0.9;
}

.buttons-container {
  justify-content: center;
  margin-top: 2em;
  display: flex;
  gap: 1em;
}

/* ==== Navbar ==== */

.navbar {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 0px 0px 0px 20px;
}

.navbar.responsive .navbar-right {
  display: flex;
}

.navbar-toggle {
  color: #231F20;
  display: none;
}

.navbar-content {
  display: flex;
  width: 100%;
  padding: 1em 2em;
}

.navbar-advice {
  padding:0.2em 2em;
  width: 100%;
  text-align: center;
  background-color: #26A282;
  color: #fff;
  display: none;
}

.navbar-advice p{
  font-size: 12px;
  text-transform: uppercase;
}

.navbar-advice.active {
  background-color: #26A282;
}

.navbar-advice.inactive {
  background-color: #E8AAC9;
}

.navbar-left {
  width: 35%;
  display: flex;
  align-items: center;
}

.navbar-left-logo {
  margin-right: 20px;
}

.navbar-left-logo img{
  max-width: 100px;
  width: 100%;
}

.navbar-right-background {
  width: 90%;
  display: flex;
  justify-content: flex-end;
  background-color:#F4F0EC;
  padding: 0.8em 0.8em 0.8em 3em;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.navbar-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navbar-link {
  text-transform: uppercase;
  text-decoration: none;
  color: #26A282;
  margin-right: 40px;
}

.navbar-link.active {
  text-decoration: underline;
  font-weight: bold;
}

.navbar-button {
  font-family: 'AvenirNextCyr-Regular', sans-serif;
  background-color: #26A282;
  color: #F4F0EC;
  border: none;
  padding: 8px 12px;
  border-radius: 15px;
  cursor: pointer;
  margin-right: 20px;
}

.navbar-button.secondary {
  background-color: #E8AAC9;
}

/* === Pop up === */

.popup-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.popup {
  background-color: #EDE4D9;
  padding: 2em;
  margin: 0.5em 0 0.5em 0;
  border-radius: 5px;
  max-height: 90%;
  width: 40%;
  overflow-y:auto;
}

.popup.job-offer {
  width: 75%;
  max-height: 85%;
}

.popup button {
  width: 100%;
}

.popup-intro h2{
  font-size: 35px;
  margin-bottom: 0.2em;
}

.popup-intro {
  text-align: center;
}

.popup-header {
  display: flex;
  justify-content: flex-end;
}

.popup-header-close {
  max-width: 40px;
  margin-bottom: 1em;
}

.popup-header-close button {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'AvenirNextCyr-Regular', sans-serif;
  color: #545454;
  border-radius: 10px;
  padding: 0.4em 0.8em;
  font-size: 20px;
  line-height: 1;
  margin: 0;
  letter-spacing: 0;
}

.popup-intro-description {
  display: flex;
  justify-content: center;
}

.popup-intro p{
  max-width: 95%;
  font-size: 16px;
}

.popup label {
  padding: 0;
  border: none;
  margin: 0 0.5em 0.5em 0.5em;
}

.popup-footer {
  margin-top: 1em;
  text-align: center;
}

.popup-footer p{
  font-size: 16px;
}

.popup-footer a{
  font-size: 16px;
  text-decoration: underline;
}

.popup-title {
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom:0.5em;
}

.popup-title h2 {
  text-align: center;
}

.popup-form .popup-form-row label {
  font-size: 15px;
  cursor:auto;
  padding:0;
  border: none;
}

.popup-form-rows {
  margin: 2em 0 0 0;
}

.popup-form-rows textarea {
  padding: 1em;
  min-height: 15em;
  border-radius: 20px;
}

.popup-form-double-col {
  display: flex;
  gap: 1em;
}

.popup-form-row {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}

.popup-form-label {
  display: flex;
  width: 100%;
  padding: 0.5em 1em;
}

.popup-form-label label {
  font-size: 13px;
  margin: 0;
  text-transform: uppercase;
  cursor: pointer;
}

.popup-select-form-row {
  width: 100%;
  border: 1px solid #545454;
  border-radius: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  cursor: pointer;
}

.popup-select-form-row:hover{
  background-color: #f2f2f2;
}

.popup-form-icon {
  max-width: 20px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  padding-right: 1em;
}

.popup-form-icon img {
  width: 50%;
}

.popup-auth-message {
  margin-top: 0.5em;
}

.form-group {
  width: 100%;
  margin-bottom: 15px;
}

label {
  display: block;
}

input, select, textarea {
  outline: none!important;
  border: 2px solid transparent;
  font-family: 'AvenirNextCyr-Regular', sans-serif;
  color: #231F20;
  width: 100%;
  padding: 8px;
  border-radius: 20px;
  box-sizing: border-box;
  cursor: pointer;
}

select:disabled {
  cursor: not-allowed;
}

.popup-basic-form-row {
  width: 100%;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.popup-form .popup-basic-form-row label{
  font-size: 15px;
  cursor:auto;
  padding:0;
  border: none;
  text-align: left;
}

.popup-form .popup-basic-form-row textarea{
  min-height: 100px;
}

/* ==== Missing Field Form ==== */

.missing-field input,
.missing-field select,
.missing-field textarea {
  /* border: 2px solid #E8AAC9; OLD */
  border: 2px solid red;
}

.error-message {
  font-size: 12px;
  text-align: center;
  color: red;
}

.loading-message {
  margin-top: 1em;
}

.loading-message p{
  font-family: 'AvenirNextCyr-Bold';
  text-transform: uppercase;
  font-size: 14px;
}

/* ==== Home Page: Hero Section ==== */

.hero-section {
  display:flex;
  align-items: center;
  min-height: 600px;
  box-sizing: border-box;
  width: 100%;
}

.hero-left-col {
  width: 50%;
  text-align: center;
}

.hero-image {
  display: flex;
  justify-content: flex-end;
  margin-right: 2em;
  margin-bottom: -2em;
}

.hero-image img {
  width: 16%;
}

.hero-left-col h1{
  width: 80%;
  text-transform: uppercase;
  font-size: 90px;
  font-family: 'ValkyRegular', sans-serif;
  color:#231F20;
  margin: 0;
}

.hero-left-col h3{
  width: 45%;
  text-transform: uppercase;
  font-size: 35px;
  font-family: 'ValkyRegular', sans-serif;
  color:#231F20;
}

.hero-title {
  justify-content: center;
  display: flex;
}

.hero-subtitle {
  justify-content: center;
  display: flex;
  padding: 3em;
}

.hero-image-background {
  /* background-image: url('/Users/tomasdiaz/Desktop/BabySitterApp/frontend/src/images/Vectors/HH_HeroShape2.svg'); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.hero-right-col {
  width:50%;
  z-index: -1;
}

.hero-right-col img{
  width:100%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}

/* ==== Home Page: Rotative Banner Section ==== */

.rotative-banner {
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  padding: 20px;
  text-align: center;
  position: relative;
  height: 150px;
  box-sizing: border-box;
  margin-bottom: 2.5em;
}

.line {
  font-family: 'AvenirNextCyr-Regular', sans-serif;
  text-transform: uppercase;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  gap: 30px;
  position: absolute;
  width: 100%;
  font-size: 22px;
  font-weight: 300;
}

.line1 {
  top: 20%;
  animation: moveRight 10s linear infinite;
}

.line2 {
  top: 50%;
  animation: moveLeft 10s linear infinite;
}

.line3 {
  top: 80%;
  animation: moveRight 10s linear infinite;
}

.phrase {
  text-align: center;
  flex-shrink: 0;
}

.color1 {
  color: #D4EE72;
}

.color2 {
  color: #CD7BEA;
}

.color3 {
  color: #29AAE1;
}

.color4 {
  color: #26A282;
}

.color5 {
  color: #F4ADCE;
}

.color6 {
  color: #57CAE2;
}


@keyframes moveRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes moveLeft {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* ==== Home Page: What We Offer Section ==== */

.what-we-offer-section {
  background-color: #F5F6F1;
  padding: 2em;
}

.what-we-offer-cards {
  padding: 2em 0 0 0;
  display: flex;
}

.what-we-offer {
  padding:2em 0;
}

.what-we-offer img{
  width: 100%;
  max-width: 120px;
}

.section-title {
  font-family: 'AvenirNextCyr-Bold', sans-serif;
  padding: 1em 0;
  text-align: center;
}

.section-title h2{
  margin: 0 0 0.4em 0;
}

.what-we-offer p {
  max-width: 80%;
  font-size: 20px;
  text-align: center;
}

.what-we-offer-card {
  flex: 1; 
  text-align: center;
  display: flex;
  flex-direction: column;
}

.what-we-offer-card h5{
  font-weight: 700;
  font-size: 15px;
  text-transform: uppercase;
  color: #231F20;
}

.what-we-offer-card p{
  font-size: 15px;
}

.what-we-offer-card-description {
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* ==== Home Page: What Do You Need Section ==== */

.what-do-you-need-section {
  padding:2em 10em;
}

.what-do-you-need-cards {
  display: flex;
  gap: 8em;
  padding: 2em 0;
  justify-content: center;
}

.what-do-you-need-card {
  width: 60%;
  max-width: 400px;
  background-color: #F4F0EC;
  border-radius: 20px;
  border: 1px solid #231F20;
  padding: 1.8em;
  box-sizing: border-box;
}

.what-do-you-need-card h5{
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1.8px;
  margin:0 0 0.5em 0;
}

.what-do-you-need-card p{
  font-family:'HelveticaNeueRoman';
  line-height: 1.6;
  letter-spacing: 2px;
  font-size: 12px;
  margin:1.8em 0;
  max-width: 80%;
}

.what-do-you-need-card-image {
  width: 100%;
  margin-bottom: 1em;
  text-align: center;
}

.what-do-you-need-card-image img{
  width: 100%;
  border-radius: 20px;
}

/* ==== Home Page: About Happy Home ==== */

.about-happy-home {
  display: flex;
  background-color: #F4F0EC;
}

.about-happy-home-col{
  width: 50%;
}

.about-happy-home-image {
  overflow: hidden;
  width: 100%;
  line-height: 0;
}

.about-happy-home-image img {
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit:cover;
}

.about-happy-home p{
  font-size: 17px;
  line-height: 1.6;
}

.about-happy-home-text{
  padding:2em;
}

.about-happy-home-text p{
  width: 70%;
}

.about-happy-home-text h2{
  width: 100%;
  margin-bottom: 0.4em;
}

/* ==== Home Page: Why Choose Happy Home ==== */

.why-choose-happy-home {
  padding:4em;
}

.why-choose-happy-home-cards {
  display: flex;
  gap: 1.5em;
  margin-top: 2em;
}

.why-choose-happy-home-card {
  border-radius: 20px;
  background-color: #F4F0EC;
  border: 1px solid #231F20;
  padding: 4em 2em;
  text-align: center;
}

.why-choose-us-description {
  width: 100%;
  display: flex;
  justify-content: center;
}

.why-choose-us-description p {
  font-size: 20px;
  width: 70%;
  line-height: 1.5;
}

.why-choose-us-image {
  width: 100%;
  margin-bottom: 1em;
}

.why-choose-us-image img{
  width: 35%;
}

/* === Memberships Page ===*/

.memberships-page-container {
  padding: 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 12em 0;
}

.memberships-page-container .loading-spinner {
  margin-top: 4em;
}

.memberships-page-container .error-message {
  margin-top: 4em;
}

.membership-type-cards-container {
  margin-top: 3em;
  display: flex;
  justify-content: center;
  width: 100%;
}

.membership-type-cards {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 3em;
}

.membership-type-card {
  box-sizing: border-box;
  width: 100%;
  background-color: #F4F0EC;
  border-radius: 40px;
  border:1px solid #231F20;
  padding: 2em 3em;
}

.membership-type-card-star img{
  max-height: 55px;
}

.membership-type-card-details {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  gap: 2em;
}

.membership-type-card-details hr{
  border: 1px solid black;
  margin: 20px 0;
}

.membership-type-card-star img{
  width: 100%; 
}

.membership-type-card-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-content: center;
}

.membership-type-card-title h3{
  font-size: 20px;
  text-transform: uppercase;
  color: #48AA8D;
  /* color: #231F20; */
  margin-bottom: 0.8em;
}

.membership-type-card-title p{
  font-size: 15px;
  text-transform: uppercase;
  color: #231F20;
  font-weight: bold;
}

.membership-type-card-description p{
  color: #231F20;
  font-size: 16px;
  margin-bottom: 2em;
}

.membership-type-card-description-green {
  color: #48AA8D;
  font-weight: bold;
}

/* === Payments === */



.payment-method-container {
  padding: 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10em 0;
}

.payment-method-container .section-title p {
  margin: 0 auto;
  max-width: 40%;
  padding-bottom: 1em;
}

.payment-details-section {
  border: 1px solid #231F20;
  border-radius: 20px;
  padding: 20px;
  margin: 0 auto;
}

.payment-details-section .profile-edit-section-title{
  text-align: center;
}

.payment-details-container {
  margin: 0 auto;
  background-color: #F4F0EC;
  padding: 1em;
  border-radius: 20px;
  text-align: left;
}

.payment-details-container p{
  font-size: 15px;
}

.payment-details-container p strong{
  font-family: 'AvenirNextCyr-Bold', sans-serif;
  text-transform: uppercase;
}

/* === Workers Page  === */

.workers-page-container {
  width: 100%;
}

.workers-list .section-title{
  text-align: left;
}

.workers-list .loading-spinner { 
  min-height: 60vh;
}

.workers-list .image-container .loading-spinner { 
  min-height: 0;
}

.workers-list .error-message { 
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.workers-list .section-title h2{
  text-transform:none;
  font-size: 20px;
}

.workers-list {
  width: 100%;
  margin: 0 auto;
  padding: 2em;
  background-color: #fff;
  box-sizing: border-box;
}

.grid-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: .8em;
}

/* === Workers Page: Worker Card === */

.grid-container-workers {
  width: 70%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 auto;
  grid-gap: .8em;
}

.worker-card {
  background-color: #F4F0EC;
  border-radius: 20px;
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
}

.worker-card:hover {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
}

.worker-container {
  width: 100%;
  height: 210px;
  display: flex;
}

.left-container {
  width: 30%;
  background-color: #f2f2f2;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.worker-profile-image {
  width: 100%;
  max-width: 300px;
  object-fit: cover;
}

.default-image-container {
  position: relative;
  text-align: center;
}

.default-image-message {
  width: 75%;
  margin-top: 1em;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -20%);
  color: #26A282;
  font-size: 14px;
  padding: 10px;
  border-radius: 5px;
  z-index: 1;
}

.worker-profile-image.hidden {
  display: none;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.worker-card .worker-profile-image {
  width: 100%;
  height: 100%;
}

.profile-image {
  width: 100%;
}

img.profile-image {
  height: 100%;
}

.right-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 80%; 
  padding: 15px 20px;
}

.worker-top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.worker-name p{
  font-size: 18px;
  font-family: 'AvenirNextCyr-Regular', sans-serif;
}

.worker-name {
  min-height: 0;
  margin-bottom:0.5em;
}

.job-link .worker-name {
  min-height: 45px;
  margin-bottom:0.5em;
}

.worker-name strong{
  font-family: 'AvenirNextCyr-Bold', sans-serif;
  text-transform: uppercase;
}

.worker-details-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.worker-detail-line {
  margin-right: 20px;
  white-space: nowrap;
  font-size: 14px;
}

.worker-divider {
  margin-bottom: 10px;
}

.worker-titles {
  margin-bottom: 10px;
}

.worker-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.worker-services {
  margin-top: 10px;
  min-height: 60px;
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
}

.worker-services-none-hr {
  margin-top: 10px;
  min-height: 60px;
  /* border-bottom: 1px solid #fff; */
  padding-bottom: 10px;
}

.worker-service {
  display: inline-block;
  background-color: #E8AAC9;
  color: #231F20;
  padding: 5px 10px;
  border-radius: 20px;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  font-size: 12px;
}

.worker-work-arrangement {
  margin-top: 10px;
  display: inline-block;
  color: #231F20;
  border-radius: 20px;
  margin-right: 0.5em;
  font-size: 12px;
}

.worker-languages {
  display: flex;
  gap:0.2em;
  align-items: center;
}

.worker-language {
  display: inline-block;
  color: #231F20;
  font-size: 12px;
  font-weight: 600;
}

.worker-introduction {
  min-height: 35px;
  margin: 10px 0px;
}

.worker-introduction p {
  font-size: 14px;
}


/* === Job Offers Page ===*/

.job-offers-page-container {
  width: 100%;
}

.job-offers-search-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top:12em;
  padding: 0em 2em;
}

.job-offers-list .section-title{
  text-align: left;
}

.job-offers-list .section-title h2{
  text-transform:none;
  font-size: 20px;
}

.job-offers-list {
  width: 100%;
  margin: 0 auto;
  padding: 2em;
  background-color: #fff;
  box-sizing: border-box;
}

.no-results-container{
  min-height: 50vh;
  border-radius: 20px;
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #F4F0EC;
}

.no-results-container p {
  max-width: 80%;
}

.job-offers-list .loading-spinner { 
  min-height: 60vh;
}

.job-offers-list .error-message { 
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.job-offers-list .worker-service {
  background-color: #E8AAC9;
}

.job-offer-work-arrangements {
  width: 100%;
}

.job-offer-footer {
  display: flex;
  align-items: baseline;
}

.job-offer-footer .job-offer-author p{
  font-size: 12px;
}

.job-offer-footer .job-offer-author {
  width: 30%;
  display: flex;
  justify-content: flex-end;
}

/* === Workers Page: Search Bar & Filters === */

.worker-search-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top:12em;
  padding: 0em 2em;
}

.filter-buttons {
  padding: 0 20px;
  background-color: #F4F0EC;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-sizing: border-box;
  border-radius: 40px;
}

.filter-button {
  margin-right: 10px;
  box-sizing: border-box;
}

.filter-buttons button {
  font-size: 14px;
  padding: 5px 10px;
  background-color: #26A282;
  color: #F4F0EC;
  cursor: pointer;
  border: 1px solid transparent;
  text-transform: uppercase;
  font-family: 'AvenirNextCyr-Regular', sans-serif;
}

.filter-options {
  display: block;
  position: absolute;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top: none;
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  z-index: 3;
  visibility: hidden;
  margin-top: 13px;
}

.filter-options.visible {
  visibility: visible;
}

.filter-options.hidden {
  visibility: hidden;
}

.filter-button button:hover {
  opacity: 0.8;
}

.filter-button.active button {
  background-color: #E8AAC9; 
  border-color: #E8AAC9;
  color: #231F20;
}

.filter-button .clear {
  background-color: transparent;
  color: #231F20;
  padding: 0;
  border:none;
}

.filter-button .clear:hover {
  text-decoration: underline;
}

.filter-container {
  display: flex;
  flex-direction: column;
  padding: 1em;
  background-color: #F4F0EC;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.filter-select {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  margin-top: 5px;
}

.filter-option {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 1.5em;
  margin-top: 5px;
}

.filter-option-label {
  font-family: 'AvenirNextCyr-Bold', sans-serif;
  width: 100%;
}

.location-filter .filter-option {
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 1em;
  padding:0;
  gap: 0.5em;
  text-transform: uppercase;
}

.filter-option-icon {
  cursor: pointer;
  width: 100%;
  max-width: 20px;
  display: flex;
  justify-content: flex-end;
}

.filter-option-select {
  width: 100%;
}

.filter-option-select select {
  min-width: 15em;
}

.filter-option input[type="checkbox"] {
  margin-right: 5px;
}

.shifts-filter-content {
  font-size: 12px;
  background-color: #F4F0EC;
}

.shifts-filter-calendar-table {
  width: 100%;
  border-collapse: collapse;
}

.shifts-filter-calendar-table td,.shifts-filter-calendar-table th {
  text-transform: uppercase;
  padding: 0.2em;
}

.shifts-filter-calendar-table td {
  font-family: 'AvenirNextCyr-Bold', sans-serif;
  font-size: 14px;
}

.shifts-filter-calendar-table th {
  font-family: 'AvenirNextCyr-Regular', sans-serif;
  font-size: 12px;
}

.shift-calendar-icon {
  display: flex;
  justify-content: center;
}

.shift-calendar-row {
  cursor: pointer;
}

.shifts-filter-calendar-table img {
  max-width: 20px;
  height: auto;
  cursor: pointer;
}

.shift-calendar-col {
  cursor: pointer;
}

.location-municipality-filter .filter-option {
  margin-bottom: 0;
}
/* === RESPONSIVE SEARCH  === */

.mobile-filter-button {
  display: none;
}

.mobile-filter-button button {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
}

@media (max-width: 768px) {

  input, select, textarea {
    padding: 6px;
    background-color: #fff;
  }

  select {
    min-height: 0.625rem;
  }

  .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .loading-spinner-message {
    font-size: 0.6em;
  }
  
  .shifts-filter-calendar-table td, .shifts-filter-calendar-table th {
    font-size: 10px;
  }

  .worker-search-container, .job-offers-search-container {
    padding: 0em 1em;
    margin-top: 6em;
    overflow: hidden;
  }

  .filter-container {
    padding: 0.5em;
  }
  

  .mobile-filter-button {
    display: block;
  }
  

  .filter-buttons.hide {
    display: none;
  }

  .filter-buttons.show {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
    box-sizing: border-box; 
  }

  .filter-buttons {
    padding: 1em 0 0 0;
    background-color: #fff;
    margin-bottom: 0em;
    height: auto;
    border-radius: 0;
  }

  .filter-buttons button {
    font-size: 12px;
  }

  .filter-option-select select {
    min-width: 0;
  }

  .filter-option {
    font-size: 12px;
    letter-spacing: 0.6px;
  }

  .filter-button {
    margin-bottom: 5px;
  }

  .filter-options {
    margin-top: 0;
  }


}

/* === Workers Page: Pagination === */

.pagination {
  padding: 10px 0;
  display: flex;
  gap: 0.4em;
  flex-direction: row;
  list-style: none;
  justify-content: flex-end;
}

.pagination .page-link {
  font-family: 'AvenirNextCyr-Regular', sans-serif;
  font-size: 14px;
  padding: 5px 10px;
  text-transform: none;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #231F20;
  border-radius: 8px;
}

.pagination .page-link:hover {
  text-decoration: underline;
}

.page-link.clear {
  border: none;
  background-color: transparent;
}

/* === My Account (Private) ===*/

.email-verification-content {
  margin-top: 0.3em;
  width: 100%;
}

.verify-email-container {
  margin-top: 0.2em;
  display: flex;
  flex-direction: column;
  gap: 0.2em;
}

.verify-email {
  color: #26A282;
  text-decoration: underline;
}

.verify-email-message p {
  color: #26A282;
  font-weight: 600;
}

.user-account-container {
  display: flex;
  justify-content: center;
  margin:12em 0;
}

.user-account-content {
  width: 70%;
}

.user-profile-container {
  width: 100%;
}

.user-profile-container .loading-spinner{
  height: 20vh;
}

.user-account-actions {
  width: 100%;
  display: flex;
  align-items: center;
}

.job-offers .user-account-actions {
  margin-bottom: 2.5em;
}

.parent-membership .user-account-actions {
  margin-bottom: 2.5em;
}

.user-account-actions-left {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.user-account-actions-right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

/* === My Account: Profile ===*/

.profile-edit-section {
  padding: 20px;
  border: 1px solid #231F20;
  border-radius: 20px;
  text-align: center;
}

.profile-edit-section .error-message {
  margin-top: 1em;
}

.profile-edit-section-title {
  font-size: 20px;
  color: #545454;
  margin-bottom: 0.5em;
}

.profile-edit-section-subtitle {
  font-size: 15px;
  color: #545454;
  margin-bottom: 0.5em;
}

.profile-edit-section-content {
  background-color: #F4F0EC;
  border-radius: 20px;
  font-size: 15px;
  padding: 1em;
  margin-bottom: 1em;
  text-align: left;
}

.empty-message {
  text-align: center;
  color:#26A282;
  padding: 1.5em;
}

.profile-edit-section-content .empty-message strong{
  color:#26A282;
  font-family: 'AvenirNextCyr-Regular', sans-serif;
  text-transform: none;
}

.profile-edit-section.job-offers {
  padding: 0;
  border: none;
  margin-top: 4em;
}

.profile-edit-section.membership {
  padding: 0;
  border: none;
  margin-top: 4em;
}

.profile-edit-section-content p{
  font-size: 15px;
}

.profile-edit-section-content .profile-edit-section-info p{
  font-size: 15px;
  font-family: 'AvenirNextCyr-Regular', sans-serif;
}

.profile-edit-section-content.calendar {
  background-color: #fff;
}

.profile-edit-section-content strong {
  font-size: 15px;
  color: #545454;
  font-family: 'AvenirNextCyr-Bold', sans-serif;
  text-transform: uppercase;
}

.profile-edit-container {
  width: 100%;
  display: flex;
  gap: 2.5em;
  margin-top: 2.5em;
}

.container-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
}

.parent-profile-edit-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  margin-top: 2.5em;
}

.parent-profile-section {
  display: flex;
  align-items: flex-start;
  gap: 2.5rem;
}

.parent-profile-data-user-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.popup-job-offer-form {
  width: 100%;
  display: flex;
  gap: 2em;
}

.edit-job-offer-intro p{
  font-family: 'AvenirNextCyr-Regular', sans-serif;
  max-width: 100%;
  font-size: 16px;
}

.edit-job-offer-intro {
  text-align: center;
}

.edit-job-offer-title {
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 0.2em;
}

.edit-job-offer-description {
  display: flex;
  justify-content: center;
}

.popup-form-col {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.profile-edit-section-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.profile-edit-section-list-item {
  color: #545454;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 15px;
}

/* Profile Image */

.profile-edit-section-main-image {
  position: relative;
  width: 50%;
  border-radius: 20px;
}

.profile-edit-section-main-image img {
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.profile-edit-section-image {
  border-radius: 20px;
  text-align: center;
}

.profile-edit-section-content-image {
  display: flex;
  justify-content: center;
  position: relative;
  border-radius: 20px;
}

.profile-edit-section-content-image .loading-spinner {
  min-height: 30rem;
}

.profile-edit-section-upload {
  margin-top: 10px;
}

.profile-edit-section-upload input {
  display: none;
}

.profile-edit-section-upload label {
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.profile-edit-section-main-image .actions {
  line-height: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.actions label,
.actions svg {
  background-color: #26A282;
  color: white;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
}

.actions .fa-trash {
  background-color: #E8AAC9;
}

/* === My Account (Parent): Job Offer List Structure ===*/

.job-offers {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.job-offer-list-empty {
  display: flex;
  padding: 2em;
  text-align: center;
  flex-direction: column;
}

.job-offer-list-empty p {
  max-width: 75%;
}

.job-offer-list {
  padding: 0;
}

.job-offer-item {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  background-color: #fff;
  padding: 0.5em 2em;
  border-radius: 40px;
  margin-bottom: 1em;
}

.job-offer-item-left {
  width: 100%;
  display: flex;
  gap: 1em;
  align-items: center;
}

.job-offer-item-left h3{
  font-size: 15px;
}

.job-offer-item-right {
  width: 100%;
}

.job-offer-item-right .buttons-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

.job-offer-item-right .buttons-container button {
  padding: 6px 12px;
  font-size: 12px;
}

.job-offer-status-toggle {
  display: flex;
  align-items: center;
  margin-left: 2em;
}

.job-offer-status-toggle span {
  font-size: 14px;
  text-transform: uppercase;
  font-family: 'AvenirNextCyr-Bold', sans-serif;
  margin-right: 10px;
}

.status-toggle-btn {
  width: 25px;
  border-radius: 0px;
  cursor: pointer;
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
}

.job-offer-item-right .buttons-container .job-offer-status-toggle button {
  padding: 0;
}

.job-offer-status-icon {
  height: 25px;
  border: 1px solid #231F20;
  background-color: #fff;
  width: 100%;
}

.job-offer-status-icon img{
  height: 100%; 
}

/* === My Account (Parent): Add Job Offer ===*/

.edit-job-offer-section {
  padding: 1em;
  border: 1px solid #231F20;
  border-radius: 20px;
}

.edit-job-offer-options {
  padding: 0.5em;
  margin-top: 0.5em;
  background-color: #f2f2f2;
  border-radius: 10px;
}

.edit-job-offer-option {
  display: flex;
  align-items: center;
  margin: 0.5em 0 0.5em 0;
}

.edit-job-offer-options textarea {
  border-radius: 10px;
  min-height: 150px;
}

.edit-job-offer-options .edit-job-offer-option label{
  font-size: 15px;
  cursor:auto;
  padding:0;
  border: none;
}

.edit-job-offer-option-icon {
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.edit-job-offer-option-icon img {
  width: 50%;
}

.edit-job-offer-native-language {
  margin-top: 2em;
  text-align: center;
}

.popup .edit-job-offer-native-language label {
  font-size: 15px;
  margin-bottom: 1em;
}

.profile-edit-popup {
  width: 350px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.button-container {
  margin-top: 10px;
}

/* === My Account (Parent): Membership ===*/

.parent-membership {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.table-container {
  overflow-x: auto;
}

.membership-table {
  border-collapse: collapse;
  width: 100%;
}

.membership-table thead tr th{
  text-align: left;
  color: #545454;
  font-size: 14px;
  text-transform: uppercase;
  font-family: 'AvenirNextCyr-Bold', sans-serif;
}

/* .membership-table tbody tr {

} */

.membership-table tr div {
  padding: 0.5em 0;
}

.membership-table tbody tr td{
  color: #545454;
  font-size: 14px;
}

.membership-table .last-column{
  text-align: right;
}

.membership-table .buttons-container {
  justify-content: flex-start;
  margin-top: 0;
}

.membership-table .buttons-container button {
  padding: 6px 12px;
  font-size: 12px;
}

/* === Parent Profile: Public Page ===*/

.parent-profile-container {
  border: 1px solid #ddd;
  padding: 20px;
  margin: 20px;
  background-color: #f9f9f9;
}

.parent-profile-container h3 {
  color: #231F20;
}

.parent-profile-container p {
  margin: 10px 0;
  color: #555;
}

.experience-list, .education-list  {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.experience-item, .education-item {
  border-bottom: 0.5px solid #545454;
  border-radius: 0px;
  padding: 10px 10px 20px 10px;
  margin-bottom: 10px;
  text-align: left;
}

.experience-item p, .education-item p {
  margin: 5px 0;
  font-family: 'AvenirNextCyr-Regular', sans-serif;
}

.profile-edit-section-calendar {
  margin-top: 20px;
  background-color: #fff;
}

.profile-edit-section-calendar-table {
  color:#545454;
  width: 100%;
  border-collapse: collapse;
}

.profile-edit-section-calendar-table th {
  font-weight: 300;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
}

.profile-edit-section-calendar-table td {
  font-family: 'AvenirNextCyr-Bold', sans-serif;
  font-size: 15px;
  text-transform: uppercase;
}

.spacer-row {
  height: 1em;
}

.profile-edit-section-calendar-table td:not(:first-child) {
  text-align: center;
}

.worker-profile-shift-update {
  margin-top: 2em;
}

.update-calendar-container {
  border-radius: 20px;
  background-color: transparent;
  padding: 0em;
}

.update-calendar-row-cell {
  display: flex;
  justify-content: center;
  margin-bottom: 0.8em;
}

.update-calendar-row-icon {
  display: flex;
  justify-content: center;
  width: 25px;
}

.update-calendar-col, .update-calendar-row {
  cursor: pointer;
  text-align: center;
}

.update-calendar-row-day {
  text-align: left;
}

.update-calendar {
  text-align: center;
  width: 100%;
  border-collapse: collapse;
}

.update-calendar td {
  font-family: 'AvenirNextCyr-Bold', sans-serif;
  font-size: 15px;
  text-transform: uppercase;
}

.update-calendar th {
  padding: 8px;
  text-align: left;
  font-weight: 300;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
}

img.icon-svg {
  width: 100%;
  border: 1px solid #231F20;
  background-color: #fff;
}

.cell {
  background-color: #f2f2f2;
}

/* === Job Offer: Public Page ===*/

.job-offer-view {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10em;
  margin-bottom: 4em;
}

.job-offer-view-section {
  width: 100%;
  display: flex;
  gap: 1em;
}

.job-offer-view-container {
    width: 100%;
    max-width: 1200px;
    padding: 2em;
}

.job-offer-view-container .loading-spinner {
  min-height: 60vh;
}
/* === Worker Profile: Public Page ===*/

.worker-profile-view {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10em;
  margin-bottom: 4em;
}

.worker-profile-view-container {
  width: 100%;
  max-width: 1200px;
  padding:2em;
}

.worker-profile-view-section {
  width: 100%;
  display: flex;
  gap: 1em;
}

.worker-profile-view-section-intro,
.job-offer-view-intro {
  margin-bottom: 1em;
  width: 100%;
  display: flex;
  gap: 4em;
  background-color: #F4F0EC;
  border-radius: 40px;
}

.view-column-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 3em;
  width: 100%;
  border:3px solid #F4F0EC;
  border-radius:40px;
  padding: 3em;
}

.view-column-container.profile-image {
  padding:3em 0 3em 3em;
}

.view-column-container.info {
  padding:3em 3em 3em 0em;
  gap: 2em;
}

.profile-view-data-section {
  width: 100%;
}

.profile-view-data-section p{
  font-size: 15px;
  font-family: 'AvenirNextCyr-Regular', sans-serif;
  color:#545454;
}

.profile-view-data-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.profile-view-data-section ul p{
  font-size: 15px;
  font-family: 'AvenirNextCyr-Bold', sans-serif;
  color:#545454;
  margin-bottom: 0.6em;
}

.profile-view-data-section-intro {
  width: 100%;
  border-bottom: 4px solid #26A282;
  margin-bottom: 2em;
}

.profile-view-data-section h2 {
  font-size: 24px;
  line-height: 1.8;
  color: #26A282;
}

.profile-view-data-section .image {
  width: 75%;
  border-radius: 40px;
  margin: 40px auto;
}

.profile-view-data-section .image img{
  width: 100%;
  border-radius: 40px;
}

.profile-view-data-section .education ul p,
.profile-view-data-section .experience ul p,
.profile-view-data-section .info ul p {
  font-family: 'AvenirNextCyr-Regular', sans-serif;
}

.profile-view-data-section .education li,
.profile-view-data-section .experience li {
  border-bottom: 2px solid #F4F0EC;
  margin-bottom: 1em;
}

.profile-view-data-section .education p strong,
.profile-view-data-section .experience p strong,
.profile-view-data-section .info ul p strong {
  font-family: 'AvenirNextCyr-Bold', sans-serif;
}

.profile-view-data-section .hour-price p {
  font-family: 'AvenirNextCyr-Bold', sans-serif;
}

.profile-view-data-section-name {
  margin-bottom: 1.2em;
}

.profile-view-data-section .shifts th{
  font-size: 12px;
  font-family: 'AvenirNextCyr-Regular', sans-serif;
  text-transform: uppercase;
}

.calendar-view-day {
  font-size: 15px;
  font-family: 'AvenirNextCyr-Bold', sans-serif;
  text-transform: uppercase;
}

table.availability {
  width: 60%;
}

/* === Footer ===*/

.footer {
  display: flex;
  background-color: #F4F0EC;
  padding: 4em 2em;
  justify-content: space-between;
}

.footer-column {
  flex: 1;
  margin: 0 10px;
}

.footer-logo {
  max-width: 60%;
  height: auto;
}

.footer-column a {
  display: block; 
  text-decoration: none;
  margin-bottom: 8px;
}

.footer-column a:hover {
  text-decoration: underline;
}

.footer-column-title {
  font-weight: 800;
  font-size: 15px;
  margin-bottom: 1em;
}

/* ================ RESPONSIVE ================ */




@media (min-width: 768px) {
  .chat-container {
    flex-direction: row;
  }

  .chat-sidebar-container {
    display: block;
    width: 20%;
  }

}


@media screen and (max-width: 600px) {

  /* === General ===*/

  body {
    font-family: 'AvenirNextCyr-Regular', sans-serif;
    color: #545454;
    letter-spacing: 1px;
    line-height: 1.2;
  }
    
  h2 {
    font-size: 20px;
    font-weight: 800;
  }
  
  h3 {
    font-family: 'AvenirNextCyr-Bold', sans-serif;
    margin:0;
  }
  
  h4 {
    font-family: 'AvenirNextCyr-Bold', sans-serif;
  }
  
  h5 {
    font-family: 'AvenirNextCyr-Bold', sans-serif;
  }
  
  p {
    font-family: 'AvenirNextCyr-Regular', sans-serif;
    font-size: 14px;
    margin: 0;
  }
  
  a {
    font-family: 'AvenirNextCyr-Regular', sans-serif;
    font-size: 12px;
    color: #545454; 
    text-decoration: none;
    cursor: pointer;
  }

  .primary-btn, .secondary-btn {
    width: 100%;
    font-size: 12px;
    padding:1em;
  }

  .buttons-container {
    gap: 0.5em;
    margin-top: 1em;
  }

  /* ==== Contact Page ==== */

  .contact-page-container {
    margin: 5em 0 2em 0;
    padding: 1em;
  }

  /* ==== FAQs ==== */

  .faqs-page-container {
    margin: 5em 0 2em 0;
    padding: 1em;
  }

  .faq-item {
    padding: 10px 0;
  }

  .faq-question h3 {
    font-size: 14px;
  }

  .faq-icon {
    font-size: 14px;
  }

  .faq-answer {
    padding: 10px 0;
  }

  .faq-answer p{
    font-size: 14px;
    color: #545454;
  }

  /* ==== Terms ==== */

  .terms-page {
    margin: 5em 0 0 0;
    padding: 1em;
  }

  .terms-page h2 {
    font-size: 20px;
    margin-bottom: 1em;
  }

  .terms-page p  {
    font-size: 12px;
    margin-bottom: 1em;
  }

  .terms-page h3 {
    font-size: 14px;
    margin-bottom: 1em;
  }

  /* === Hero ===*/

  .hero-section {
    min-height: 0;
    margin-top: 4.5em;
  }

  /* .hero-left-col {
    
  } */

  /* .hero-right-col {
    
  } */

  .hero-left-col h1{
    font-size: 27px;
    width: 100%;
  }

  .hero-image {
    margin-right: -1em;
    margin-bottom:0;
  }

  .hero-image img {
    width: 12%;
  }

  .hero-left-col h3{
    font-size: 12px;
    width:70%;
  }

  .hero-image-background {
    padding: 0.5em;
    margin-left: 1em;
  }

  .hero-title {
    padding: 0 0 0 1em;
   
  }

  .hero-subtitle {
    padding: 0em;
    margin-top:0.5em;
  }

  /* === Home: Rotative Banner ===*/
  .rotative-banner {
    height: 65px;
    margin-bottom: 1em;
    margin-top:1em;
  }
  
  .line {
    font-size: 10px;
  }

  /* === About Happy Home ===*/

  .about-happy-home-col {
    text-align: center;
  }

  .about-happy-home-text p{
    width: 100%;
  }

  .about-happy-home-text {
    padding:1em;
  }

  /* === Pop Up ===*/

  .popup {
    width: 85%;
    padding: 1em;
  }

  .popup-basic-form-row {
    gap: 0;
  }

  .popup-form .popup-basic-form-row label {
    font-size: 12px;
  }

  .popup-intro h2{
    font-size: 16px;
    margin-bottom: 0.5em;
  }

  .popup-intro p {
    max-width: 100%;
    font-size: 12px;
  }

  .popup-footer a, .popup-footer p{
    font-size: 12px;
  }
  
  .popup-form-double-col {
    gap: 0.5em;
  }

  .form-group {
    margin-bottom: 0.5em;
  }

  .popup label {
    font-size: 12px;
  }

  .popup-title {
    font-size: 16px;
  }

  /* === Forgot Password  ===*/

  .forgot-password-container, .reset-password-page-container {
    margin: 5em 0 2em 0;
    padding: 1em;
  }

  .forgot-password-link {
    font-size: 12px;
  }

  /* === My Account (Parent)  ===*/

  .user-account-container {
    margin: 0em 0em 1em 0em;
  }

  .user-account-content {
    width: 90%;
    margin-top: 5em;
  }

  .profile-edit-container {
    margin-top: 1em;
  }

  .profile-edit-section-content-image .loading-spinner {
    min-height: 22rem;
  }

  .container-column {
    gap: 1em;
  }

  .parent-profile-section {
    flex-direction: column;
    gap: 1em;
  }

  .parent-profile-data-user-section {
    flex-direction: column;
    gap: 1em;
  }

  .profile-edit-section {
    padding: 15px;
  }

  .parent-profile-edit-container {
    gap: 1em;
    margin-top: 1em;
  }

  .profile-edit-section-title {
    font-size: 18px;
  }

  .profile-edit-section-content p,
  .profile-edit-section-content strong {
    font-size: 14px;
  }

  .profile-edit-section-content .profile-edit-section-info p {
    font-size: 14px;
  }

  .profile-edit-section.membership {
    margin-top: 1em;
  }

  .parent-membership .user-account-actions {
    margin-bottom: 1em;
  }

  .membership-table, .membership-table tbody, .membership-table tr {
    display: block;
  }

  .membership-table thead {
    display: none;
  }

  .membership-table tr {
    margin-bottom: 10px;
  }

  .membership-table td {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border: none;
    border-bottom: 1px solid #ddd;
  }

  .membership-table td:before {
    content: attr(data-label);
    font-weight: bold;
    flex-basis: 50%;
  }

  .membership-table td:last-child {
    border-bottom: 0;
  }

  .profile-edit-section.job-offers {
    margin-top: 1em;
  }

  .job-offers .user-account-actions {
    margin-bottom: 1em;
  }

  .job-offer-item {
    padding: 1em;
    flex-direction: column;
    border-radius: 20px;
  }

  .buttons-container {
    flex-direction: column;
  }

  .job-offer-item-left {
    width: 100%;
    margin-bottom: 1em;
  }

  .job-offer-status-icon {
    height: 20px;
  }

  .job-offer-status-toggle {
    margin: 0;
    justify-content: flex-end;
  }

  .popup-header-close button {
    padding: 0.2em 0.4em;
    font-size: 18px;
  }

  .popup.job-offer {
    width: 85%;
    max-height: 85%;
  }

  .popup-form-col {
    gap: 1em;
  }

  .popup-form-icon {
    padding-right: 0.5em;
  }

  .popup-form-label {
    padding: 0.4em 0.8em;
  }

  .popup-job-offer-form {
    flex-direction: column;
    gap: 0.5em;
  }

  .popup-select-form-row {
    margin-bottom: 0.5em;
  }

  .edit-job-offer-title {
    font-size: 18px;
  }

  .update-calendar td {
    font-size: 14px;
  }

  .spacer-row {
    height:0.5em;
  }

  .update-calendar-row-icon {
    width: 20px;
  }

  /* === My Account (Worker)  ===*/

  .profile-edit-container {
    gap:1em;
  }

  .profile-edit-container {
    flex-direction: column;
  }

  .profile-edit-section-calendar-table td {
    font-size: 12px;
  }

  .job-offer-list-empty p {
    max-width: 100%;
  }

  .job-offer-list-empty {
    padding: 0.5em;
  }

  /* === Navbar ===*/

  .navbar-toggle {
    display: block;
    background-color: #fff;
    border-radius: 0;
    padding: 0;
  }

  .navbar {
    padding: 0;
  }

  .navbar-content {
    box-sizing: border-box;
    padding: 0.5em 1em;
  }

  .navbar-left {
    width: 100%;
  }

  .navbar-left-logo img {
    max-width: 50px;  
  }

  .navbar-right {
    padding: 1em;
    display: none;
  }

  .navbar.responsive .navbar-right {
    width: 100%;
    display: flex;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    gap:0.5em;
  }

  .navbar-right-background {
    position: absolute;
    width: 100%;
    justify-content: flex-start;
    padding: 0em;
    border-radius:0;
    top: 74px;
    left: 0;
  }

  .navbar-button {
    font-size: 12px;
    padding: 0.5em;
  }

  /* === Home: What We Offer ===*/

  .what-we-offer {
    padding: 1em 0;
  }

  .what-we-offer-cards {
    padding: 1em 0;
    flex-direction: column;
    gap: 2em;
  }

  /* === Home: What Do You Need ===*/

  .what-do-you-need-section {
    padding: 1em;
  }

  .what-do-you-need-cards {
    flex-direction: column;
    gap: 1em;
  }

  .what-do-you-need-card {
    width: 100%;
  }

  /* === Home: About Us ===*/

  .about-happy-home {
    flex-direction: column;
  }

  .about-happy-home-col {
    width: 100%;
  }

  .about-happy-home-text h2 {
    width: 100%;
  }

  .about-happy-home-text p {
    font-size: 12px;
    width: 100%;
  }


  /* === Home: Why Choose Us ===*/

  .why-choose-us-description p {
    font-size: 14px;
  }

  .why-choose-us-image img {
    width: 25%;
  }

  .why-choose-happy-home-cards {
    flex-direction: column;
    gap: 1em;
  }

  .why-choose-happy-home {
    padding: 1em;
  }

  .memberships-page-container {
    margin: 5em 0 2em 0;
    padding: 1em;
  }

  .payment-method-container {
    margin: 5em 0 2em 0;
    padding: 1em;
  }

  .payment-method-container .section-title p {
    max-width: 100%;
  }

  .payment-details-section {
    margin: 0;
    padding: 1em;
  }

  .payment-details-container {
    padding:1em;
  }

  .membership-type-cards {
    gap: 1em;
  }

  .membership-type-cards-container {
    margin-top: 1em;
  }

  .membership-type-card-details {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 1em;
    gap: 0.5em;
  }

  .membership-type-card-star img {
    max-height: 35px;
  }

  .membership-type-card {
    padding: 1.5em;
  }

  .membership-type-card-title p {
    font-size: 12px;
  }

  .membership-type-card-title h3 {
    font-size: 16px;
    margin-bottom: 0.5em;
  }

  .membership-type-card-description p {
    font-size: 14px;
    margin-bottom: 1.5em;
  }
  
  /* === Worker Profile & Job Offer: Public Page ===*/

  .worker-profile-view-container, .job-offer-view-container {
    padding: 1em;
  }

  .worker-profile-view-section-intro, .job-offer-view-intro {
    flex-direction: column;
    gap: 1em;
  }

  .worker-profile-view-section {
    flex-direction: column;
  }

  .view-column-container.profile-image {
    padding: 0em;
    border: none;
  }

  .view-column-container.info {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .worker-profile-view-section-intro, .job-offer-view-intro {
    gap: 0;
  }

  .profile-view-data-section .image {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #F4F0EC;
  }

  .profile-view-data-section .image img {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .worker-profile-view, .job-offer-view {
    margin-top: 6em;
    margin-bottom: 0;
  }

  table.availability {
    width: 100%;
  }

  .view-column-container.info {
    background-color: #F4F0EC;
  }

  .view-column-container.info {
    padding: 1em;
  }

  .view-column-container {
    padding:1em;
    gap: 1em;
  }

  .profile-view-data-section-intro {
    margin-bottom: 1em;
  }

  .profile-view-data-section h2 {
    font-size: 18px;
  }

  .profile-view-data-section ul p, .profile-view-data-section p {
    font-size: 14px;
  }
  
  .worker-profile-view-section-intro, .job-offer-view-intro {
    background-color: #fff;
  }

  .job-offer-view-section {
    flex-direction: column;
  }

  /* === Workers Page ===*/

  .workers-list {
    padding: 1em;
  }

  .worker-container {
    flex-direction: column;
  }

  .grid-container {
    display: flex;
    flex-direction: column;
  }

  .left-container {
    width: 100%;
  }

  .right-container {
    width: 100%;
    padding: 15px;
  }

  .worker-name p {
    font-size: 14px;
  }

  .worker-detail-line {
    font-size: 12px;
  }
  
  .worker-introduction p {
    font-size: 12px;
  }

  .worker-service {
    font-size: 8px;
  }

  /* === Job Offer List ===*/

  .job-offers-list {
    padding: 1em;
  }

  /* === Footer ===*/

  .footer {
    flex-direction: column;
    padding: 2em 1em;
    gap: 1em;
  }

  .footer-logo {
    max-width: 30%;
  }

  .footer-column {
    margin: 0;
  }

  .footer-column-title {
    font-size: 14px;
    margin-bottom: 0.5em;
  }

  /* Chat */

  .message-timestamp {
    font-size: 0.6rem; 
  }

  .user-chat-page {
    padding: 5em 0em 0em 0em;
    border-bottom: 1px solid #ddd;
  }

  .chat-container {
    border-radius: 0;
  }

  .chat-sidebar-container {
    width: 100%;
    display: block;
  }

  .no-conversations-message h2 {
    font-size: 20px;
  }

  .no-conversations-message p {
    font-size: 14px;
    max-width: 100%;
  }

  .choose-conversation p{
    font-size: 14px;
  }

  .chat-advice-fixed-message p{
    font-size: 10px;
    max-width: 65%;
  }

}


/* === Register ===*/

.terms-container {
  display: grid;
  grid-template-columns: auto 1fr;
  text-align: left;
  align-items: flex-start;
}









/* === Worker Card Component Styles === */

.grid-container-workers {
  width: 70%;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Dos columnas en pantallas grandes */
  margin: 0 auto;
}

.worker-card-nw {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f4f0ec;
  border-radius: 15px;
  overflow: hidden;
  height: 220px; /* Altura fija en pantallas grandes */
  width: 100%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  gap: 1em; /* Espaciado interno */
  transition: all 0.3s ease; /* Transiciones suaves */
}

/* Left: Image */
.worker-image-container-nw {
  flex: 0 0 200px; /* Tamaño proporcional */
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  margin-left: .5em;
}

.worker-profile-image-nw {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Llena el contenedor sin distorsión */
  object-position: right; /* Centra la imagen */
  border-radius: 12px;
}

/* Right: Content */
.worker-content-nw {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  text-align: left;
}

.worker-name-nw {
  font-weight: bold;
  font-size: 1em;
  color: #333;
}

.worker-location-nw {
  font-size: 0.9em;
  color: #777;
}

.worker-job-type-nw {
  font-size: 0.85em;
  color: #555;
}

.worker-job-arrangement-nw {
  display: inline-block;
  background-color: #e8aac9;
  color: #231f20;
  padding: 4px 8px;
  border-radius: 12px;
  margin-right: 0.5em;
  font-size: 0.8em;
}

.worker-introduction-nw {
  font-size: 0.9em;
  color: #444;
}

.worker-divider-nw {
  border: none;
  border-top: 1px solid #ddd;
  margin: 0.5em 0;
}

.worker-services-nw {
  display: flex;
  flex-wrap: wrap;
  gap: 0.4em;
}

.worker-service-nw {
  background-color: #e8aac9;
  color: #231f20;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.8em;
}

.worker-availability-nw {
  font-size: 0.85em;
  color: #555;
  margin-top: 0.5em;
}

/* Responsive Design */
@media (max-width: 768px) {
  .grid-container-workers {
    grid-template-columns: 1fr; /* Una columna en tablets */
  }

  .worker-card-nw {
    flex-direction: column; /* Cambia a columna en tablets */
    align-items: center; /* Centra el contenido */
    height: auto; /* Altura dinámica */
    padding: 0.5em; /* Menor relleno interno */
  }

  .worker-image-container-nw {
    flex: 0 0 200px; /* Ajuste proporcional */
    height: 120px;
    margin: 0 auto; /* Centra la imagen */
  }

  .worker-content-nw {
    text-align: center;
    gap: 0.5em;
  }
}

@media (max-width: 480px) {
  .grid-container-workers {
    grid-template-columns: 1fr; /* Una columna en móviles */
  }

  .worker-card-nw {
    flex-direction: column; /* Cambia a columna */
    height: auto; /* Altura dinámica */
    margin-bottom: 1em;
  }

  .worker-image-container-nw {
    flex: 0 0 200px; /* Imagen proporcional */
    height: 100px;
    margin: 0 auto;
  }

  .worker-content-nw {
    text-align: center;
    gap: 0.5em;
  }
}



.grid-container-workers {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Dos columnas en pantallas grandes */
  gap: 1rem; /* Espaciado entre cards */
  width: 90%;
  margin: 0 auto;
}

@media (max-width: 1100px) {
  .grid-container-workers {
    grid-template-columns: 1fr; /* Una columna en tablets y móviles */
  width: 100%;

  }
}

