
  
/* ==== Sidebar ==== */

.sidebar {
    width: 20%;
    background-color: #f0f0f0;
}
  
.admin-panel-content {
    flex: 1; 
    padding: 20px; 
    background-color: #ffffff;
}

/* ==== Admin Panel ==== */
.admin-panel-container {
  display: flex;
  flex-direction: column;
  background-color: #F4F0EC;
  border-radius: 40px;
  padding: 2em;
  box-sizing: border-box;
  overflow: auto;
}

.admin-panel-error-container .error-message{
  display: flex;
  width: 100%;  
  margin-bottom: 1em;
}

.admin-panel-workers-page, 
.admin-panel-parents-page,
.admin-panel-job-offers-page,
.admin-panel-memberships-page,
.admin-panel-payments-page,
.admin-panel-stats-page  {
  padding:0 2em;
  margin-top: 12em;
  margin-bottom: 6em;
}

/* ==== Admin Panel Table ==== */

.admin-panel-table {
  border-collapse: collapse;
  width: 100%;
}

.admin-panel-table thead tr th{
  text-align: left;
  color: #545454;
  font-size: 14px;
  text-transform: uppercase;
  font-family: 'AvenirNextCyr-Bold', sans-serif;
}

.admin-panel-table tbody tr {
  border-bottom: 1px solid #ccc;
}

.admin-panel-table tr div {
  padding: 0.5em 0;
}

.admin-panel-table tbody tr td{
  color: #545454;
  font-size: 14px;
}

.admin-panel-table .last-column{
  text-align: right;
}

.admin-panel-table .buttons-container {
  justify-content: flex-start;
  margin-top: 0;
}

.admin-panel-table .buttons-container button {
  padding: 6px 12px;
  font-size: 12px;
}

.admin-panel-table .user-profile-visibility-toggle,
.admin-panel-table .admin-panel-parent-membership,
.admin-panel-table .job-offer-status-toggle,
.admin-panel-table .membership-status-toggle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.admin-panel-table .admin-panel-parent-membership {
  gap: 0.5em;
}

.admin-loading-message {
  width: 100%;
  font-size: 14px;
  text-align: center;
}


/* ==== Admin Panel: Users ==== */

.admin-panel-users {
  margin: 20px;
}

.admin-panel-users h2 {
  margin-bottom: 10px;
}

.admin-panel-users table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
}

.admin-panel-users th, 
.admin-panel-users td {
  padding: 8px;
  border: 1px solid #ccc;
}

.admin-panel-users th {
  background-color: #f2f2f2;
}

.admin-panel-users tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.admin-panel-users tbody tr:hover {
  background-color: #ddd;
}

.parent-membership-status {
  cursor:default;
  width: 100%;
  max-width: 20px;
  display: flex;
  justify-content: flex-end;
}

.parent-membership-status .icon-svg {
  border: none;
  background-color: transparent;
}

/* ==== Admin Panel: Memberships ==== */

.admin-panel-memberships {
  margin: 20px;
}

.admin-panel-memberships h2 {
  margin-bottom: 10px;
}

.admin-panel-memberships table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
}

.admin-panel-memberships th, 
.admin-panel-memberships td {
  padding: 8px;
  border: 1px solid #ccc;
}

.admin-panel-memberships th {
  background-color: #f2f2f2;
}

.admin-panel-memberships tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.admin-panel-memberships tbody tr:hover {
  background-color: #ddd;
}

.admin-panel-memberships-page  .loading-spinner {
  padding: 0;
}

.admin-panel-memberships-page  .loading-spinner .loading-spinner-message {
  font-size: 0.5rem;
}



.admin-panel-memberships-page .loading-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
  padding: 0;
}

/* ==== Admin Panel: Memberships TYPES ==== */

.membership-types-container {
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
}

.membership-types-buttons {
  display: flex;
  gap: 0.5em;
}

/* ==== Admin Panel: Stats ==== */

.admin-panel-stats-page .admin-panel-data {
  margin-top: 2em;
}

.admin-panel-stats-page .admin-panel-table tbody tr {
  border: none;
}

/* === ADMIN PANEL FILTER */ 

.admin-panel-filter {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.admin-panel-filter input[type="text"] {
  padding: 15px;
  width: 100%;
  max-width: 350px;
  border: 1px solid #F4F0EC;
  background-color: #F4F0EC;
  border-radius: 40px;
}

@media (max-width: 768px) { 

  .admin-panel-workers-page, 
  .admin-panel-parents-page,
  .admin-panel-job-offers-page,
  .admin-panel-memberships-page,
  .admin-panel-payments-page,
  .admin-panel-stats-page {
    padding:0 1em;
    margin-top: 6em;
    margin-bottom: 3em;
  }

  .admin-panel-data {
    margin-top: 1em;
  }

  .admin-panel-container {
    display: flex;
    border-radius: 20px;
    padding: 10px;
  }

  .admin-panel-table tbody tr td,
  .admin-panel-table thead tr th  {
    white-space: nowrap;
    padding-right: 1em;
  }

  .admin-panel-filter {
    margin-bottom: 10px;
  }

  .admin-panel-filter input[type="text"] {
    padding: 10px;
    max-width: none;
  }

  .admin-panel-table .buttons-container {
    flex-direction: row;
  }

  .admin-panel-table .buttons-container button {
    flex-direction: row;
    padding: 3px 6px;
    white-space: nowrap;
  }

  .membership-types-buttons button {
    padding: 3px 6px;
    white-space: nowrap;
  }
  

}
